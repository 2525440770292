import styled from "styled-components";
import Icons24_20 from "../../assets/svg/24/Icons24px-20.svg"
import {maxCol, minCol} from "../../function/SizeCol";

const PopStyle = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    background-color: var(--paper);
    overflow: hidden;
    overflow-y: scroll;
    .wrap {
        display: flex;
        flex-direction: column;
    }
    
    &.open {
        height: 100%;
        .header {
            position: fixed;
            left: 2rem;
            right: 2.4rem;
            max-width: calc(1400px - 0.2rem - 0.2rem);
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;
            z-index: 20;
        }
    }
    .header {
        padding-right: 0.2rem;
        padding-left: 0;
        padding-right: 0;
        .row {
            //border-right: 0.2rem solid var(--dark);
            //border-left: 0.2rem solid var(--dark);
        }
        
        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            left: -1000px;
            right: -1000px;
            z-index: -1;
            background-color: var(--paper);
        }
        &:before {
            top: -0.2rem;
            bottom: -0.2rem;
            border-top: 0.2rem solid var(--dark);
            border-bottom: 0.2rem solid var(--dark);
        }
        &:after {
            bottom: calc(100% + 0.2rem);
            top: -100px;
            z-index: -2;
        }
    }
    &.wrapPop {
        .container, .wrap {
            //min-height: 100%;
            //height: 100%;
        }
        .wrapData {
            //height: 100%;
            //min-height: calc(100vh - 1.8rem - 1.8rem);
            min-height: calc(100vh - 1.8rem);
            &:after {
                bottom: 1.8rem;
            }
            padding-bottom: 1.8rem;
        }
    }
    .pos {
        position: relative;
    }
    .container {
        //height: calc(100vh - 1.8rem * 2);
        //min-height: calc(100vh);
        padding-top: 1.8rem;
        //padding-bottom: 1.8rem;
    }
    //.border-ver:before, .border-ver:after {
    //    bottom: -5rem; 
    //}
 
    .wrapLogo {
        padding-left: 8.6rem;
        padding-right: 8.6rem;
        height: 6.4rem;
        @media (max-width: ${maxCol.xl}) {
            height: 5.6rem;
            padding-left: 4.2rem;
            padding-right: 4.2rem;
        }
        @media (max-width: ${maxCol.sm}) {
            padding: 0 2.4rem;
            //justify-content: center;
            img {
                max-width: 9rem;
            }
        }
    }
    .wrapHeaderIcon {
        width: 6.4rem;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: ${maxCol.xl}) {
            width: 5.6rem;
        }
    }
    .wrapData { 
        //min-height: calc(100vh);
    }
    .iconBG20:before { background-image: url(${Icons24_20}); }
    .wrapContainer {
        padding-top: calc(3.8rem + 6.8rem);
        padding-bottom: 3.8rem;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        max-width: calc(900px + 2.4rem * 2);
        @media (max-width: ${maxCol.md}) {
            padding-top: calc(3.8rem + 5.6rem);
        }
    }
    
    .row-section {
        background-color: var(--paper);
        &:before {
            //left: -2px;
        }
        &:after {
            //right: -2px;
        }
        //&:before {
        //    background-color: var(--paper);
        //    content: '';
        //    position: absolute;
        //    bottom: 100%;
        //    height: 1.8rem;
        //    left: -10000px;
        //    right: -10000px;
        //}
        //&:after { 
        //    content: '';
        //    display: block;
        //    background-color: var(--paper);
        //    position: absolute;
        //    left: -1000px; 
        //    right: -1000px;
        //    height: 100%;
        //    top: 0;
        //    z-index: -1;
        //}
    }
    
    .wrapTitleSearch {
        .col {
            padding-top: 1.8rem;
            padding-bottom: 1.8rem;
            @media (min-width: ${minCol.xl}) {
                border-right: 2px solid var(--dark);
                &:first-child {
                    border-left: 2px solid var(--dark);
                    border-right: none;
                }   
            }
            &:first-child {
                border-right: 2px solid var(--dark);
            }
        }
        a {
            text-decoration: none;
        }
    }
    
    .wrap-img {
        border: 0.2rem solid var(--gray);
        padding-bottom: 60%;
        position: relative;
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            height: 100%;
            width: auto;
            object-fit: cover;
            object-position: center center;
        }
    }
    .category {
        a {
            margin-left: 1.4rem;
            @media (max-width: ${maxCol.sm}) {
                margin-left: 0;
                margin-right: 0.8rem;
                margin-top: 0.4rem;
            }
        }
    }
    
    
`
export default PopStyle;