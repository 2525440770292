import React, {useState} from 'react';
import {Link} from "gatsby";

const ItemSubMenuPop = ({item, index, menuNumber, triggerMenuPop}) => {

    const [open, setOpen ] = useState(false);

    const clickSubMenu = (menuNumber) => {
        setOpen(!open)
        // console.log(open, menuNumber);
        // open ? (
        //     document.body.classList.remove('ovh')
        // ) : ( document.body.classList.add('ovh') )
    };

    return (
        <li className={item.childItems.nodes && item.childItems.nodes.length > 0 ? 'sub' : ''}>

            {item.path === '#' ? (
                <button type="button" aria-labelledby="button-label" onClick={() => clickSubMenu(menuNumber)} className='a'>{item.label}</button>
            ) : (
                <Link onClick={() =>triggerMenuPop()} to={item.path.replace("category/", "")}>{item.label}</Link>
            )}


            {item.childItems.nodes && item.childItems.nodes.length > 0 ? (
                <>
                    <ul className={open ? `open ul-sub ul-clear anim` : `close ul-sub ul-clear anim`}>
                        <li>
                            <button className='return' onClick={() => clickSubMenu(menuNumber)} type="button" aria-labelledby="button-label">{item.label}</button>
                        </li>
                        {item.childItems.nodes.map((li, index_li) => (
                            <li key={`sub_menu_pop_` + menuNumber + `_` + item.id + `_` + index + `_` + index_li}>
                                <Link onClick={() =>triggerMenuPop()} className="a" to={li.path.replace("category/", "")}>{li.label}</Link>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}
        </li>
    )
}
export default ItemSubMenuPop;