import styled from 'styled-components'
import {maxCol, minCol} from "../../function/SizeCol";
// import FooterDecore_1 from "../image/footer-decore-1.jpg"
import FooterDecore_1 from "../image/grd_single_sm2.svg"
import Icons24_21 from "../svg/24/Icons24px-21.svg"
import Icons24_22 from "../svg/24/Icons24px-22.svg"
import Icons24_23 from "../svg/24/Icons24px-23.svg"
import Icons24_24 from "../svg/24/Icons24px-24.svg"
import Icons_email from "../svg/email.svg"

const WrapFooter = styled.footer`
    margin-top: -2px;
    background-color: var(--dark);
    color: var(--paper);
    z-index: 10;  
    position: relative;
    min-height: 50vh; 
    
    .footer-1 {
        padding-top: 3rem;
        min-height: 11rem;
        background-color: var(--paper);
        &:before {
            content: '';
            height: 8rem;
            left: 0;
            right: 0;
            background-image: url(${FooterDecore_1});
            background-size: auto 8rem;
            background-position: 0 0;
            //background-repeat: repeat-x;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    .footer-2 {
        overflow: hidden;
        .itemPost.border-hor-bottom:after {
            background-color: var(--gray);
        }
        
        .wrap-post {
            position: relative;
            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                top: -1000px;
                bottom: 0px;
                left: 0;
                background-color: var(--gray);
                width: 2px;
                z-index: 95; 
            }
            &:after {
                right: 0;
                left: auto;
            }
        }
        
    }
    .WrapFooterItem {
        padding: 6.2rem;
        @media (max-width: ${maxCol.lg}) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        @media (max-width: ${maxCol.md}) {
            padding: 4rem 0rem;
        }
    }
    .wrapMenuForm {
         .wrap {
             @media (min-width: ${minCol.xl}) {
                 padding-right: 4rem;
                 max-width: 76rem;
             }
         }
    }
    .WrapDesc {
        .row {
            --bs-gutter-x: 1.7rem; 
        }
        .text {
            margin-bottom: 2.4rem;
            @media (min-width: ${minCol.xl}) {
                max-width: 45.5rem;
            }
        }
        .desc {
            p {
                margin: 0 0 1.2rem;
            }
        }
        .wrapScroll {
            padding-right: 2.4rem;
            overflow: auto;
            max-height: 20rem;
            @media (max-width: ${maxCol.md}) {
                max-height: 30rem;
            }
            /* width */
            &::-webkit-scrollbar {
                width: 2px;
                height: 4px;
            }
            // #86644B 
            /* Track */
            &::-webkit-scrollbar-track {
                background-color: var(--gray);
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: var(--paper);
            }
        }
    } 

    .iconBG21:before  { background-image: url(${Icons24_21}); }
    .iconBG22:before  { background-image: url(${Icons24_22}); }
    .iconBG23:before  { background-image: url(${Icons24_23}); }
    .iconBG24:before  { background-image: url(${Icons24_24}); }
    .icons_email:before  {
        background-image: url(${Icons_email}); 
        height: 1.8rem;
    }

    .wrap-network-icon {
        border: 1px solid var(--gray);
        border-top: none;
        border-bottom: none;
        .btn {
            background-color: var(--dark);
            border-left-width: 1px;
            border-right-width: 1px;
        }
    }
    .wrap-form {
        padding-bottom: 4.8rem;
    }
    .wrapMenu {
        @media (max-width: ${maxCol.sm}) {
            border-top: 2px solid var(--gray);
            padding-top: 4.8rem;
            padding-bottom: 1.6rem;
        }
    }
    .logo {
        margin-bottom: 1rem;
    }
    @media (max-width: ${maxCol.sm}) {
        .copyright {
            padding-top: 4.8rem;
            margin-top: 4.8rem;
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: -2000px;
                right: -2000px;
                background-color: var(--gray);
                height: 2px;
                top: 0;
            }
        }
        .wrap-copyright {
            overflow: inherit;
        }
        .wrapMenuForm {
            padding-bottom: 0;
        }
        .WrapFooterItemEnd {
            overflow: inherit;
            &.border-hor-top:after {
                left: 0;
                right: 0;
            }
        }
    }
`;
export default WrapFooter;