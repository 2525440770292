// src/components/layout.js
import React from "react"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import GlobalStyles from "../assets/styles/GlobalStyles"
// import SmoothScroll from '../components/SmoothScroll';

const Layout = ({ children, props }) => {

    // console.log('>>>>>>>>>>>>>>>>>>>>>> type:', children.type )


    // const getData = props.pageContext === {} && true : false ;
    const getDataPageContext = props?.pageContext;
    const dataKey = props?.location.key;

    // console.log('getData >>>>>>>>>', dataKey, props)




    // console.log('is404 >>>>>>>>>', is404 , props)

    const templateName = props.pageContext?.templateFix || props.pageContext?.template?.templateName;

    // Функція для визначення класу шаблону сторінки
    const getTemplateClass = () => {
        if (Object.keys(getDataPageContext).length  === 0) {
            return "page404";
        } else {
            return templateName
        }
        // return templateName || "customPage";
    };

    const templateClass = getTemplateClass();

    return (
        // <SmoothScroll>
            <div
                className={`body template-${templateClass} ${
                    templateClass === 'page404' ? 'colorRevers' : ''
                }`}
            >
                <GlobalStyles />
                <div className="container">
                    <div className="wrapMain border-ver">
                        <Header colorRevers={templateClass === 'page404' ? 'colorRevers' : 'colorNormal'} />
                        <div className='main'>
                            {children}
                        </div>
                    </div>
                </div>
                {templateClass === 'page404' ? null : (
                    <Footer isFrontPage={getDataPageContext?.isFrontPage} />
                )}
            </div>
        // </SmoothScroll>
    );
}

export default Layout;
