// functions.js

/**
 * Повертає значення з масиву об'єктів, де об'єкти мають { key, value }.
 * Якщо елемент не знайдено, повертає null.
 *
 * @param {Array} metaDataArray - Масив метаданих
 * @param {string} searchKey - Ключ, який шукаємо
 * @returns {*} - Значення, або null
 */
export const getMetaValue = (metaDataArray, searchKey) => {
    if (!Array.isArray(metaDataArray)) {
        console.error("metaDataArray має бути масивом");
        return null;
    }
    const foundItem = metaDataArray.find(item => item.key === searchKey);
    return foundItem ? foundItem.value : null;
};

/**
 * Зберігає будь-яке значення (масив, об’єкт, рядок тощо) у LocalStorage.
 *
 * @param {string} key - Ключ для LocalStorage
 * @param {*} value - Дані для зберігання
 */
export const saveToLocal = (key, value) => {
    if (typeof window === "undefined") return;
    try {
        const json = JSON.stringify(value);
        localStorage.setItem(key, json);
    } catch (error) {
        console.error(`Помилка при збереженні у LocalStorage: ${error}`);
    }
};

/**
 * Зчитує дані з LocalStorage за заданим ключем.
 *
 * @param {string} key - Ключ LocalStorage
 * @returns {*} - Розпарсені дані, або null
 */
export const getFromLocal = (key) => {
    if (typeof window === "undefined") return null;
    try {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(data) : null;
    } catch (error) {
        console.error(`Помилка при зчитуванні з LocalStorage: ${error}`);
        return null;
    }
};

/**
 * Очищає весь LocalStorage
 */
export const clearLocalStorage = () => {
    if (typeof window === "undefined") return;
    localStorage.clear();
};